import { FT_TO_METER, METER_TO_FT } from './Conversion';
export const ST_LUCIA_CODE = 'LC';
export const ST_LUCIA_ZIP_CODE = '00000';
export const USANDC = {
    label: 'USA, Canada and St Lucia',
    stateLabel: 'State / Province',
    distanceMidUnit: 'feet',
    name: 'USANDC',
    index: 0,
    conversionValue: METER_TO_FT,
    valueFromFeet: 1.0,
    valueToFeet: 1.0,
    valueFromMeters: METER_TO_FT,
    valueToMeters: FT_TO_METER,
    distanceLongFactorFromMeter: 1.0 / 1609.34,
    maxSiteRadius: 998.0,
    minSiteRadius: 14.0,
    distanceLongUnit: 'mi',
    states: [
        { name: 'AL', value: 'AL - Alabama' },
        { name: 'AK', value: 'AK - Alaska' },
        { name: 'AZ', value: 'AZ - Arizona' },
        { name: 'AR', value: 'AR - Arkansas' },
        { name: 'CA', value: 'CA - California' },
        { name: 'CO', value: 'CO - Colorado' },
        { name: 'CT', value: 'CT - Connecticut' },
        { name: 'DE', value: 'DE - Delaware' },
        { name: 'DC', value: 'DC - District of Columbia' },
        { name: 'FL', value: 'FL - Florida' },
        { name: 'GA', value: 'GA - Georgia' },
        { name: 'HI', value: 'HI - Hawaii' },
        { name: 'ID', value: 'ID - Idaho' },
        { name: 'IL', value: 'IL - Illinois' },
        { name: 'IN', value: 'IN - Indiana' },
        { name: 'IA', value: 'IA - Iowa' },
        { name: 'KS', value: 'KS - Kansas' },
        { name: 'KY', value: 'KY - Kentucky' },
        { name: 'LA', value: 'LA - Louisiana' },
        { name: 'ME', value: 'ME - Maine' },
        { name: 'MD', value: 'MD - Maryland' },
        { name: 'MA', value: 'MA - Massachusetts' },
        { name: 'MI', value: 'MI - Michigan' },
        { name: 'MN', value: 'MN - Minnesota' },
        { name: 'MS', value: 'MS - Mississippi' },
        { name: 'MO', value: 'MO - Missouri' },
        { name: 'MT', value: 'MT - Montana' },
        { name: 'NE', value: 'NE - Nebraska' },
        { name: 'NV', value: 'NV - Nevada' },
        { name: 'NH', value: 'NH - New Hampshire' },
        { name: 'NJ', value: 'NJ - New Jersey' },
        { name: 'NM', value: 'NM - New Mexico' },
        { name: 'NY', value: 'NY - New York' },
        { name: 'NC', value: 'NC - North Carolina' },
        { name: 'ND', value: 'ND - North Dakota' },
        { name: 'OH', value: 'OH - Ohio' },
        { name: 'OK', value: 'OK - Oklahoma' },
        { name: 'OR', value: 'OR - Oregon' },
        { name: 'PA', value: 'PA - Pennsylvania' },
        { name: 'RI', value: 'RI - Rhode Island' },
        { name: 'SC', value: 'SC - South Carolina' },
        { name: 'SD', value: 'SD - South Dakota' },
        { name: 'TN', value: 'TN - Tennessee' },
        { name: 'TX', value: 'TX - Texas' },
        { name: 'UT', value: 'UT - Utah' },
        { name: 'VT', value: 'VT - Vermont' },
        { name: 'VA', value: 'VA - Virginia' },
        { name: 'WA', value: 'WA - Washington' },
        { name: 'WV', value: 'WV - West Virginia' },
        { name: 'WI', value: 'WI - Wisconsin' },
        { name: 'WY', value: 'WY - Wyoming' },
        { name: 'AB', value: 'AB - Alberta' },
        { name: 'BC', value: 'BC - British Columbia' },
        { name: 'MB', value: 'MB - Manitoba' },
        { name: 'NB', value: 'NB - New Brunswick' },
        { name: 'NL', value: 'NL - Newfoundland and Labrador' },
        { name: 'NT', value: 'NT - Northwest Territory' },
        { name: 'NS', value: 'NS - Nova Scotia' },
        { name: 'NU', value: 'NU - Nunavut' },
        { name: 'ON', value: 'ON - Ontario' },
        { name: 'PE', value: 'PE - Prince Edward Island' },
        { name: 'QC', value: 'QC - Quebec' },
        { name: 'SK', value: 'SK - Saskatchewan' },
        { name: 'YT', value: 'YT - Yukon' },
        { name: ST_LUCIA_CODE, value: 'LC - St Lucia' },
    ],
    mapCenter: { lat: 39, lng: -99 },
    mapZoom: 4,
    eulaProduct: 'Jumptrack',
};
export const UK = {
    label: 'United Kingdom',
    stateLabel: 'County',
    distanceMidUnit: 'meter',
    name: 'UK',
    index: 1,
    valueToMeters: 1.0,
    valueFromFeet: FT_TO_METER,
    valueFromMeters: 1.0,
    valueToFeet: METER_TO_FT,
    conversionValue: FT_TO_METER,
    distanceLongFactorFromMeter: 0.001,
    maxSiteRadius: 304.0,
    minSiteRadius: 4.0,
    distanceLongUnit: 'km',
    states: [
        { name: 'BEDFORDSHIRE', value: 'BEDFORDSHIRE' },
        { name: 'BERKSHIRE', value: 'BERKSHIRE' },
        { name: 'BRISTOL', value: 'BRISTOL' },
        { name: 'BUCKINGHAMSHIRE', value: 'BUCKINGHAMSHIRE' },
        { name: 'CAMBRIDGESHIRE', value: 'CAMBRIDGESHIRE' },
        { name: 'CHESHIRE', value: 'CHESHIRE' },
        { name: 'CITY OF LONDON', value: 'CITY OF LONDON' },
        { name: 'CORNWALL', value: 'CORNWALL' },
        { name: 'CUMBRIA', value: 'CUMBRIA' },
        { name: 'DERBYSHIRE', value: 'DERBYSHIRE' },
        { name: 'DEVON', value: 'DEVON' },
        { name: 'DORSET', value: 'DORSET' },
        { name: 'DURHAM', value: 'DURHAM' },
        { name: 'EAST RIDING OF YORKSHIRE', value: 'EAST RIDING OF YORKSHIRE' },
        { name: 'EAST SUSSEX', value: 'EAST SUSSEX' },
        { name: 'ESSEX', value: 'ESSEX' },
        { name: 'GLOUCESTERSHIRE', value: 'GLOUCESTERSHIRE' },
        { name: 'GREATER LONDON', value: 'GREATER LONDON' },
        { name: 'GREATER MANCHESTER', value: 'GREATER MANCHESTER' },
        { name: 'HAMPSHIRE', value: 'HAMPSHIRE' },
        { name: 'HEREFORDSHIRE', value: 'HEREFORDSHIRE' },
        { name: 'HERTFORDSHIRE', value: 'HERTFORDSHIRE' },
        { name: 'ISLE OF WIGHT', value: 'ISLE OF WIGHT' },
        { name: 'KENT', value: 'KENT' },
        { name: 'LANCASHIRE', value: 'LANCASHIRE' },
        { name: 'LEICESTERSHIRE', value: 'LEICESTERSHIRE' },
        { name: 'LINCOLNSHIRE', value: 'LINCOLNSHIRE' },
        { name: 'MERSEYSIDE', value: 'MERSEYSIDE' },
        { name: 'NORFOLK', value: 'NORFOLK' },
        { name: 'NORTH YORKSHIRE', value: 'NORTH YORKSHIRE' },
        { name: 'NORTHAMPTONSHIRE', value: 'NORTHAMPTONSHIRE' },
        { name: 'NORTHUMBERLAND', value: 'NORTHUMBERLAND' },
        { name: 'NOTTINGHAMSHIRE', value: 'NOTTINGHAMSHIRE' },
        { name: 'OXFORDSHIRE', value: 'OXFORDSHIRE' },
        { name: 'RUTLAND', value: 'RUTLAND' },
        { name: 'SHROPSHIRE', value: 'SHROPSHIRE' },
        { name: 'SOMERSET', value: 'SOMERSET' },
        { name: 'SOUTH YORKSHIRE', value: 'SOUTH YORKSHIRE' },
        { name: 'STAFFORDSHIRE', value: 'STAFFORDSHIRE' },
        { name: 'SUFFOLK', value: 'SUFFOLK' },
        { name: 'SURREY', value: 'SURREY' },
        { name: 'TYNE AND WEAR', value: 'TYNE AND WEAR' },
        { name: 'WARWICKSHIRE', value: 'WARWICKSHIRE' },
        { name: 'WEST MIDLANDS', value: 'WEST MIDLANDS' },
        { name: 'WEST SUSSEX', value: 'WEST SUSSEX' },
        { name: 'WEST YORKSHIRE', value: 'WEST YORKSHIRE' },
        { name: 'WILTSHIRE', value: 'WILTSHIRE' },
        { name: 'WORCESTERSHIRE', value: 'WORCESTERSHIRE' },
        { name: 'ANGLESEY', value: 'ANGLESEY' },
        { name: 'BRECKNOCKSHIRE', value: 'BRECKNOCKSHIRE' },
        { name: 'CAERNARFONSHIRE', value: 'CAERNARFONSHIRE' },
        { name: 'CARMARTHENSHIRE', value: 'CARMARTHENSHIRE' },
        { name: 'CARDIGANSHIRE', value: 'CARDIGANSHIRE' },
        { name: 'DENBIGHSHIRE', value: 'DENBIGHSHIRE' },
        { name: 'FLINTSHIRE', value: 'FLINTSHIRE' },
        { name: 'GLAMORGAN', value: 'GLAMORGAN' },
        { name: 'MERIONETH', value: 'MERIONETH' },
        { name: 'MONMOUTHSHIRE', value: 'MONMOUTHSHIRE' },
        { name: 'MONTGOMERYSHIRE', value: 'MONTGOMERYSHIRE' },
        { name: 'PEMBROKESHIRE', value: 'PEMBROKESHIRE' },
        { name: 'RADNORSHIRE', value: 'RADNORSHIRE' },
        { name: 'ABERDEENSHIRE', value: 'ABERDEENSHIRE' },
        { name: 'ANGUS', value: 'ANGUS' },
        { name: 'ARGYLLSHIRE', value: 'ARGYLLSHIRE' },
        { name: 'AYRSHIRE', value: 'AYRSHIRE' },
        { name: 'BANFFSHIRE', value: 'BANFFSHIRE' },
        { name: 'BERWICKSHIRE', value: 'BERWICKSHIRE' },
        { name: 'BUTESHIRE', value: 'BUTESHIRE' },
        { name: 'CROMARTYSHIRE', value: 'CROMARTYSHIRE' },
        { name: 'CAITHNESS', value: 'CAITHNESS' },
        { name: 'CLACKMANNANSHIRE', value: 'CLACKMANNANSHIRE' },
        { name: 'DUMFRIESSHIRE', value: 'DUMFRIESSHIRE' },
        { name: 'DUNBARTONSHIRE', value: 'DUNBARTONSHIRE' },
        { name: 'EAST LOTHIAN', value: 'EAST LOTHIAN' },
        { name: 'FIFE', value: 'FIFE' },
        { name: 'INVERNESS-SHIRE', value: 'INVERNESS-SHIRE' },
        { name: 'KINCARDINESHIRE', value: 'KINCARDINESHIRE' },
        { name: 'KINROSS', value: 'KINROSS' },
        { name: 'KIRKCUDBRIGHTSHIRE', value: 'KIRKCUDBRIGHTSHIRE' },
        { name: 'LANARKSHIRE', value: 'LANARKSHIRE' },
        { name: 'MIDLOTHIAN', value: 'MIDLOTHIAN' },
        { name: 'MORAYSHIRE', value: 'MORAYSHIRE' },
        { name: 'NAIRNSHIRE', value: 'NAIRNSHIRE' },
        { name: 'ORKNEY', value: 'ORKNEY' },
        { name: 'PEEBLESSHIRE', value: 'PEEBLESSHIRE' },
        { name: 'PERTHSHIRE', value: 'PERTHSHIRE' },
        { name: 'RENFREWSHIRE', value: 'RENFREWSHIRE' },
        { name: 'ROSS-SHIRE', value: 'ROSS-SHIRE' },
        { name: 'ROXBURGHSHIRE', value: 'ROXBURGHSHIRE' },
        { name: 'SELKIRKSHIRE', value: 'SELKIRKSHIRE' },
        { name: 'SHETLAND', value: 'SHETLAND' },
        { name: 'STIRLINGSHIRE', value: 'STIRLINGSHIRE' },
        { name: 'SUTHERLAND', value: 'SUTHERLAND' },
        { name: 'WEST LOTHIAN', value: 'WEST LOTHIAN' },
        { name: 'WIGTOWNSHIRE', value: 'WIGTOWNSHIRE' },
        { name: 'ANTRIM', value: 'ANTRIM' },
        { name: 'ARMAGH', value: 'ARMAGH' },
        { name: 'DOWN', value: 'DOWN' },
        { name: 'FERMANAGH', value: 'FERMANAGH' },
        { name: 'LONDONDERRY', value: 'LONDONDERRY' },
        { name: 'TYRONE', value: 'TYRONE' },
    ],
    mapCenter: {
        lat: 54.6748792,
        lng: -5.0450324,
    },
    mapZoom: 6,
    eulaProduct: 'JumpTrack-UK',
};
export const Translations = [USANDC, UK];

export const getTranslationByName = (name) => {
    const transList = Translations.filter((item) => item.name === name);
    if (transList.length === 0) {
        return USANDC;
    }
    return transList[0];
};
